import { Button, TextField } from '@material-ui/core'
import React from 'react'


// export default function PassData() {
export default class extends React.Component {

    state = {
        passNumb: null,
        birthDate: null,
    }

    onPassNumbEvent = (event) => {
        this.setState({ passNumb: event.target.value });
    }


    onBirthDateEvent = (event) => {
        this.setState({ birthDate: event.target.value });
    }

    render() {
        return (
            <>
                <TextField
                    label="Passport Number"
                    variant="outlined"
                    style={{ fontSize: 100 }}
                    onChange={this.onPassNumbEvent}
                />

                <TextField
                    label="Birthdate"
                    variant="outlined"
                    style={{ fontSize: 100 }}
                    onChange={this.onBirthDateEvent}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.props.setPassData(
                        this.state.passNumb,
                        this.state.birthDate
                    )}
                > Next</Button>
            </>
        )
    }
}

// const styles = StyleSheet.create({})
