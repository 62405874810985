// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
export async function postData(url = '', data = {}, token = '', params = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + token
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data),  // body data type must match "Content-Type" header,
        params: params
    });
    return response.status === 200 && response.json(); // parses JSON response into native JavaScript objects
}

export async function postAuthorized(url = '', token = '', params = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: params
    });
    return response.status === 200 && response.json(); // parses JSON response into native JavaScript objects
}


export const birthDateFormat = (date = new Date()) => date.toISOString().split('T')[0]