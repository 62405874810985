// import { useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string'
import Axios from 'axios';
import Layout from 'components/common/Layout';
import PassData from 'components/pass_data';
import { getAuthToken, setAuthToken } from 'helpers/auth';
import React from 'react';
// import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
import OpenCVFaceDetector from '../components/OpenCVFaceDetector';
import {
  FACE_DETECTION,
  PASS_INPUT
} from '../helpers/constants';
// import Comparision from '../components/Comparision';
import { birthDateFormat } from '../helpers/utils';





const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperNoPaddingAround: {
    padding: theme.spacing(2, 1)
  }
});

class App extends React.Component {

  state = {
    loading: false,
    auth_result: null,
    step: PASS_INPUT,
    pass_data: "",
    birth_date: birthDateFormat(),
  }

  componentDidMount() {
    const { location } = this.props;
    this.queryData = queryString.parse(location.search)
    // console.log(">> queryString", this.queryData);
  }

  authenticate = (data) => {
    // console.log(">> authenticate:", data);
    // this.setAuthResult()
    this.setState({ loading: true });

    Axios.post(`${process.env.API}/authentication/inplace-authentication-request-task`, {
      pass_data: this.state.pass_data,
      birth_date: this.state.birth_date,
      photo_from_camera: {
        front: data
      },
      agreed_on_terms: true,
      client_id: process.env.IN_PLACE_CLIENT_ID,
    }).then(result => this.setAuthResult(result))
      .catch(err => this.handleError(err))

  }

  checkAuthStatus = (uuid) => {
    console.log('>>>>> checkAuthStatus:', uuid);
    let response = null;

    const _check = (_uuid) => async () => {
      console.log(">> checkAuthStatus sending request");
      response = await Axios({
        url: `${process.env.API}/authentication/inplace-authentication-request-status`,
        method: 'post',
        data: JSON.stringify(uuid)
      });
      if (response.status === 200) {
        clearInterval(checkInterval);
        let token = response.data.access_token;
        setAuthToken(token);
        window.localStorage.setItem('token', token);
        this.authorizeClient(token);
      }
    }
    let checkInterval = setInterval(_check(uuid), 1300);
  }

  authorizeClient = (token = getAuthToken()) => {
    console.log(">> authorizeClient in");
    console.log(">> this.queryData.oauth2_id", this.queryData);
    Axios({
      url: `${process.env.API}/oauth2/authorize-client`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      params: {
        oauth2_id: this.queryData.oauth2_id,
        source: 2,
      }
    }).then((res) => {
      console.log(">> authorizeClient res:", res.data.Location);
      this.redirect(res.data)
    }).catch((err) => {
      console.log(">> authorizeClient err:", err);
    });
  }

  redirect = (data) => {
    if (!data) return;
    // if (!clearAuthToken(_ => { })) return;
    window.location = data.Location;
  }


  handleError = (err) => {
    console.log('can not connect to server', err);
    this.setState({ loading: false });
  }

  setAuthResult = (result = null) => {
    console.log('>> result received:', result);
    this.setState({ auth_result: result, loading: false });
    this.checkAuthStatus(result.data.job_id)
  }

  setPassData = (pass_data, birth_date) => {
    console.log(">> setPassData:", pass_data, birth_date);
    this.setState({
      pass_data: pass_data,
      birth_date: birth_date,
      step: FACE_DETECTION
    })
  }

  render() {
    const { classes } = this.props;
    const { loading, auth_result } = this.state;

    return (
      <div className={classes.___gatsby}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Layout>
              <div className="auth_box auth_box_change" style={{ minWidth: this.state.step ? 650 : 800 }}>
                <div className="auth_tab" style={{ textAlign: 'center' }}>
                  {
                    this.state.step === PASS_INPUT ?
                      <PassData setPassData={this.setPassData} /> :
                      <OpenCVFaceDetector
                        postData={this.authenticate}
                        result={auth_result}
                        loading={loading} />
                  }
                </div>
              </div>
            </Layout>
          </Grid>
        </Grid>
      </div>
    )
  }
}


export default withStyles(styles)(App);
